import * as React from 'react';
import { CommData, TrackerData } from '../types';
import { useSite } from './backend';
import { SiteHeader } from './site_header';
import { CommList } from './comm_list';
import { useUiState } from './ui_state';
import { TrackerMap } from './tracker_map';
import { ErrorMessage, PleaseWaitWhileLoading } from './helper';

const useCommSummary = (trackers: TrackerData[] | undefined): Map<string | number, number> | undefined => {
  return React.useMemo(() => {
    if(trackers == null) {
      return undefined;
    }

    return trackers.reduce((summary, tracker) => {
      const key = tracker.gateway;
      const count = summary.get(key);
      if(count != undefined) {
        summary.set(key, count + 1);
      } else {
        summary.set(key, 1);
      }
      return summary;
    }, new Map<(string | number), number>())

  }, [trackers]);
}

interface ContentViewProps {
  data: CommData[] | undefined;
  trackers: TrackerData[] | undefined;
}

const ContentView: React.FC<ContentViewProps> = ({ data, trackers }) => {
  const uiState = useUiState();
  const commSummary = useCommSummary(trackers);

  return <>
    <div className="list">
      <CommList data={data} uiState={uiState} commSummary={commSummary} />
    </div>
    <div className="map">
      <TrackerMap trackers={trackers} uiState={uiState} comms={data} colorMode={'comm'} />
    </div>
  </>;
}

const NoParameter: React.FC = () => {
  return <div className="state_message">No Communication Information available.</div>
}

const NoSite: React.FC = () => {
  return <div className="state_message">No Site available.</div>
}

export interface SiteCommViewProps {
  id: string;
}

export const SiteCommView: React.FC<SiteCommViewProps> = ({ id }) => {
  const siteQuery = useSite(id);
  const site = siteQuery.data;

  let content;
  if(siteQuery.isError) {
    content = <ErrorMessage error={siteQuery.error} />
  } else if(siteQuery.isLoading) {
    content = <PleaseWaitWhileLoading />;
  } else if(site == null) {
    content = <NoSite />;
  } else if(site?.communication == null || Object.keys(site?.communication).length == 0) {
    content = <NoParameter />;
  } else {
    content = <ContentView data={site.communication} trackers={site.trackers} />;
  }

  return <div className="site">
    <div className="header">
      <SiteHeader site={site} />
    </div>
    <div className="content">
      {content}
    </div>
  </div>;
}
