import * as React from 'react';
import { useState, useEffect } from "react";

export function usePromise<T>(promise: Promise<T> | undefined): [T | undefined, boolean] {
  const [result, setResult] = useState<T>();
  const [resolving, setResolving] = useState(true);

  useEffect(() => {
    setResult(undefined);

    if(promise == null) {
      setResolving(false);
      return;
    }

    setResolving(true);

    let cancelled = false;

    promise.then((data) => {
      if(cancelled) {
        return;
      }

      setResult(data);
      setResolving(false);
    });

    return () => {
      cancelled = true;
    };
  }, [promise]);

  return [result, resolving];
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const PleaseWaitWhileLoading: React.FC = () => {
  return <div className="state_message">Please wait while loading...</div>
}

const getErrorMessage = (error: unknown | undefined) => {
  if(error == undefined) {
    return undefined;
  }

  let message = 'Unknown Error';
  if (error instanceof Error) {
    message = error.message;
  }

  return message;
}

export const ErrorMessage: React.FC<{error: unknown | undefined}> = ({error}) => {
  const message = getErrorMessage(error);
  const display = message == undefined ? '.' : ( ': ' + message );
  return <div className="state_message">Sorry, there was an error{display}</div>
}