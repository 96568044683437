import * as React from 'react';
import { useSiteHistory } from './backend';
import { SiteHeaderWrapper } from './site_header';
import { SiteHistoryList } from './site_history_list';
import { ErrorMessage, PleaseWaitWhileLoading } from './helper';

export interface HistoryViewProps {
  id: string;
}

const NoParameter: React.FC = () => {
  return <div className="state_message">No History Information available.</div>
}

export const SiteHistoryView: React.FC<HistoryViewProps> = ({ id }) => {
  const query = useSiteHistory(id);
  const history = query.data;

  let content;
  if(query.isError) {
    content = <ErrorMessage error={query.error} />
  } else if(query.isLoading) {
    content = <PleaseWaitWhileLoading />;
  } else if(history == null) {
    content = <NoParameter />;
  } else {
    content = <SiteHistoryList data={history} />;
  }

  return <div className="site history">
    <div className="header">
      <SiteHeaderWrapper id={id} />
    </div>
    <div className="content">
      <div className="list">
        {content}
      </div>
    </div>
  </div>;
}
