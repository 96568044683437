import * as React from 'react';
import { ChangeEvent, useRef } from 'react';

import { Button } from '@rmwc/button';
import '@rmwc/button/styles';
import { SheetReader } from '../sheet_reader';

import * as deepEqual from 'fast-deep-equal';
import { addBoxes } from '../api';
import { useKeycloak } from '@react-keycloak/web';

const loadSerials = (file: File): Promise<Array<[string,string]>> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async (loadEvent) => {
      try {
        const data = fileReader.result;
        const reader = new SheetReader(data, file.name, true);

        const sheet = reader.readSheet();

        const headers = sheet.getColumnNames().slice(0, 2);

        if(!deepEqual(headers, ["Snr. Box", "Snr Track.bloxx"]) && !deepEqual(headers, ['Box Snr', 'PCB Snr'])) {
          reject("Does not contain right columns");
          console.log(sheet.getColumnNames());
          return;
        }

        const mapping = new Map<string,string[]>();
        const controllerMap = new Map<string,string>();

        for(const row of sheet.getData()) {
          // unpack and sanitize data

          const boxId = String(row[0]).trim();
          const controllerId = String(row[1]).trim();

          // did we see the controller id before?

          const oldBox = controllerMap.get(controllerId);

          if(oldBox != null) {
            const oldBoxControllers = mapping.get(oldBox)!;

            if(oldBox === boxId) {
              console.log(`Repeat mapping of ${controllerId} into ${boxId}`);
            } else if(oldBoxControllers.length === 1) {
              console.log(`Moving controller ${controllerId} from box ${oldBox} to ${boxId}`);
            } else {
              console.log(`Probably resolving duplicate box ${oldBox} by moving controller ${controllerId} to ${boxId}`);
            }

            // remove from old box, might let older mapping get active again
            mapping.set(oldBox, oldBoxControllers.filter((checkId) => checkId != controllerId));
          }

          // remember where we put that controller

          controllerMap.set(controllerId, boxId);

          // append controller to known mappings of that box

          const boxMapping = mapping.get(boxId);

          if(boxMapping != null) {
            boxMapping.push(controllerId);
          } else {
            mapping.set(boxId, [controllerId]);
          }
        }

        // convert history entries into actual mapping

        const entries = Array.from(mapping.entries())
          .filter(([box, controllers]) => controllers.length > 0)
          .map(([box, controllers]): [string,string] => {
            if(controllers.length > 1) {
              console.log(`More than one controller for box ${box}`);
            }

            return [box, controllers[controllers.length-1]];
          });

        resolve(entries);
      } catch(err) {
        reject("Unable to parse file");
        console.log(err);
      }
    };

    fileReader.onerror = () => {
      reject("Unable to load file from disk");
    };

    fileReader.readAsBinaryString(file);
  });
}

export const Boxes: React.SFC = () => {
  const { keycloak } = useKeycloak();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const load = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    console.log(files);

    if(files == null || files.length == 0) {
      return;
    }

    try {
      const fileCount = files.length;

      const res = await Promise.all(Array.from(files).map((file) => {
        return loadSerials(file);
      }));

      const ids = res.flat();

      const token = keycloak.token;

      if(token == null) {
        throw new Error("No authorization token found");
      }

      addBoxes(ids, token);
      alert(`Imported ${ids.length} serial pairs from ${fileCount} file(s)`);
    } catch(err) {
      alert(err);
      console.log(err);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return <div className="box_import">
    <p>Add controllers to the database:</p>
    <Button className="margin_top" raised onClick={handleClick}>Add Controller Serials</Button>
    <input style={{ display: 'none' }} ref={fileInputRef} type="file" value="" onChange={load} multiple />
  </div>;
};
